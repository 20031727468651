// I M P O R T

// Asset Class
import { aquarel } from './class/aquarel';

// Library
import { randomEntry } from '../../function/random';

// Asset Image
const imageFolder = require.context('../../../asset/bitmap', false);

// E X P O R T

export const themeArray = [{
    name: null,
    class: null,
    image: null
}, {
    name: null,
    class: aquarel,
    image: null
}, {
    name: null,
    class: null,
    image: imageFolder('./skulls.png').default
}];

export const theme = randomEntry(themeArray);