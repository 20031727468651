// I M P O R T

// Component
import Editor from './Editor/App';
import Footer from './Footer';
import Header from './Header';
import Mirror from './Mirror/App';
import Render from './Render/App';
import Status from './Status';
import Worker from './Worker';

// Element
import Canvas from '../element/Canvas';

// Library
import { colorArray } from '../library/design/color';
import { fontArray } from '../library/design/font';
import { modelArray } from '../library/design/model';
import { themeArray } from '../library/design/theme';
import { warpArray } from '../library/design/warp';
import { quote } from '../library/quote';
import { rule } from '../library/rule';

// Module
import { PureComponent } from 'react';
import styled from 'styled-components';

// Style
import '../style/app.css';
import '../style/form.css';

// S T Y L E D

const Styled = styled.div(props => {
    const { client } = props;
    const publish = rule.app.publish || client.platform === 'mobile';
    const style = `opacity: 0; top: -9999px; left: -9999px;`;
    return `
        & #mirror { ${publish ? style : null} }
        & #render { ${publish ? style : null} }
        & #status { ${publish ? style : null} }
    `;
});

// E X P O R T

export default class App extends PureComponent {

    // Construct
    constructor() {
        super();
        // State
        this.state = {
            active: {
                editorDesignList0: 7,
                editorDesignList1: 0,
                editorDesignList2: 1,
                editorDesignMenu: 0,
                editorMenu: 0
            },
            client: {
                platform: null,
                rotation: null
            },
            design: {
                colorActive: colorArray[0].active,
                colorDesign: colorArray[0].design,
                colorLayout: colorArray[0].layout,
                colorLetter: colorArray[0].letter,
                colorShadow: colorArray[0].shadow,
                fontFamily: fontArray[0].family,
                fontStyle: fontArray[0].style,
                fontWeight: fontArray[0].weight,
                modelGeometry: modelArray[0].geometry,
                modelMaterial: modelArray[0].material,
                themeClass: themeArray[0].class,
                themeImage: themeArray[0].image,
                warpColor: warpArray[0].color,
                warpDecal: warpArray[0].decal,
                warpScale: warpArray[0].scale,
                warpShift: warpArray[0].shift,
                warpSlant: warpArray[0].slant
            },
            editor: {
                placeholder: 'Type here',
                quote: quote.content,
                textArray: [],
                textPlain: '',
                textRich: ''
            },
            layout: {
                appSize: [0, 0],
                editorFormRect: { x: 0, y: 0, w: 0, h: 0 },
                editorToolRect: { x: 0, y: 0, w: 0, h: 0 },
                mirrorSize: [1200, 1200],
                renderSize: [1200, 1200]
            },
            mirror: {
                fontSizeFactor: 0,
                rows: 0,
                selection: 0
            },
            render: {
                create: null,
                design: null,
                export: null,
                figure: null
            }
        };
    }

    // Function
    set = (state) => this.setState(prevState => {
        const key = Object.keys(state)[0];
        const value = state[key];
        return { active: { ...prevState.active, [key]: value } };
    });
    setAppState = (state) => this.setState(prevState => {
        const nextState = {};
        for (let key in state) {
            nextState[key] = {
                ...prevState[key],
                ...state[key]
            };
        }
        return nextState;
    });

    // Render
    render() {
        const { client, design, layout } = this.state;
        const action = { set: this.set, setAppState: this.setAppState };
        return <Styled id="app" client={client}>
            {/* Absolute position */}
            <Canvas design={design} layout={layout} parentId="app" />
            <Mirror {...this.state} action={action} />
            <Render {...this.state} action={action} />
            <Status {...this.state} />
            <Worker {...this.state} action={action} />
            {/* Relative position */}
            <Header client={client} design={design} />
            <Editor {...this.state} action={action} />
            <Footer client={client} design={design} />
        </Styled>;
    }
}