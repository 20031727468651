// E X P O R T

export const addListener = (element, type, listener) => (
    Array.isArray(type) ?
        type.forEach(type => element.addEventListener(type, e => listener(e))) :
        element.addEventListener(type, e => listener(e))
);

export const removeListener = (element, type, listener) => (
    Array.isArray(type) ?
        type.forEach(type => element.removeEventListener(type, e => listener(e))) :
        element.removeEventListener(type, e => listener(e))
);

export const didUpdate = (state1, state2, key, callback) => {
    const keyArray = key.split('.');
    keyArray.forEach(key => state1 = state1[key]);
    keyArray.forEach(key => state2 = state2[key]);
    return state1 !== state2 ? callback() : null;
};