// I M P O R T

// Design
import { colorArray } from '../design/color';
import { fontArray } from '../design/font';
import { modelArray } from '../design/model';
import { themeArray } from '../design/theme';
import { warpArray } from '../design/warp';

// E X P O R T

export const designArray0 = [
    { color: colorArray[0], theme: themeArray[0] },
    { color: colorArray[1], theme: themeArray[1] },
    { color: colorArray[2], theme: themeArray[2] },
    { color: colorArray[3], theme: themeArray[0] },
    { color: colorArray[4], theme: themeArray[0] },
    { color: colorArray[5], theme: themeArray[0] },
    { color: colorArray[6], theme: themeArray[0] },
    { color: colorArray[7], theme: themeArray[0] },
    { color: colorArray[8], theme: themeArray[0] }
];
export const designArray1 = [
    { font: fontArray[0], warp: warpArray[0] },
    { font: fontArray[0], warp: warpArray[1] },
    { font: fontArray[0], warp: warpArray[2] },
    { font: fontArray[1], warp: warpArray[0] },
    { font: fontArray[1], warp: warpArray[1] },
    { font: fontArray[1], warp: warpArray[2] },
    { font: fontArray[2], warp: warpArray[0] },
    { font: fontArray[2], warp: warpArray[1] },
    { font: fontArray[2], warp: warpArray[2] }
];
export const designArray2 = [
    { model: modelArray[0] },
    { model: modelArray[1] },
    { model: modelArray[2] },
];