// E X P O R T

export const shift = {
    default: () => {
        return [0, 0];
    },
    diagonal: (factor = 0.25) => {
        const a = Math.round(Math.random() * 25 * factor) - 12.5 * factor;
        return [a, a];
    },
    horizontal: (factor = 0.25) => {
        const x = Math.round(Math.random() * 25 * factor) - 12.5 * factor;
        const y = 0;
        return [x, y];
    },
    vertical: (factor = 0.25) => {
        const x = 0;
        const y = Math.round(Math.random() * 25 * factor) - 12.5 * factor;
        return [x, y];
    },
    random: (factor = 0.25) => {
        const x = Math.round(Math.random() * 25 * factor) - 12.5 * factor;
        const y = Math.round(Math.random() * 25 * factor) - 12.5 * factor;
        return [x, y];
    },
    zigzag: (factor = 0.25, gross, index) => {
        const x = 0;
        const y = (index % 2 * 2 - 1) * 25 * factor - 12.5 * factor;
        return [x, y];
    }
};