// I M P O R T

// Asset
import placeholder from '../../asset/bitmap/placeholder.png';

// Library
import { rule } from '../../library/rule';

// Module
import { Suspense, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';

// M E S H

const Mesh = (props) => {
    const { Geometry, Material, render } = props;
    const meshRef = useRef();
    // Create texture from rendered image
    // If render is not available... Substitute with placeholder
    const image = render || placeholder;
    const texture = useLoader(THREE.TextureLoader, image);
    texture.minFilter = THREE.NearestFilter;
    // Apply texture to mesh
    useEffect(() => {
        const mesh = meshRef.current.material.uniforms;
        mesh.uTexture.value = texture;
    });
    // Apply effects to mesh
    useFrame(({ clock }) => {
        const mesh = meshRef.current.material.uniforms;
        mesh.uTime.value = clock.oldTime * rule.model.speed;
    });
    // Render
    return <mesh ref={meshRef}>
        <Geometry />
        <Material />
    </mesh>;
};

// E X P O R T

export default function Design(props) {

    // Asset
    const { design, render, style } = props;

    // Render
    return <div style={{ ...style, height: '100%' }}>
        <Canvas colorManagement>
            {/* Light */}
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {/* Geometry */}
            <Suspense fallback={null}>
                <Mesh
                    // Component
                    Geometry={design.modelGeometry}
                    Material={design.modelMaterial}
                    // Property
                    render={render.create}
                />
            </Suspense>
        </Canvas>
    </div>;
};