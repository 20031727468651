// I M P O R T

// Component
import Create from './Create';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { design, layout } = props;
    return `
        position: absolute;
        top: 80px;
        right: ${layout.renderSize[0] + 20 + 'px'};
        z-index: 99;

        & > div {
            box-shadow: 0 0 0 1px ${design.colorLetter[0]};
            width: ${layout.mirrorSize[0] + 'px'};
            height: ${layout.mirrorSize[1] + 'px'};
        }
    `;
});

// E X P O R T

export default function Mirror(props) {

    // Asset
    const { design, layout } = props;

    // Render
    return <Styled id="mirror" design={design} layout={layout}>
        <Create {...props} />
    </Styled>;
};