// I M P O R T

// Asset
import { IconCheck } from '../../asset/vector/icon';

// Element
import Letter from '../../element/Letter';

// Library
import { designArray0, designArray1, designArray2 } from '../../library/design';
import { hexToGradient, hexToRgba } from '../../library/function/color';
import { camelize } from '../../library/function/string';
import { rule } from '../../library/rule';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client, design, value } = props;
    const style = {};
    style.general = `
        overflow-y: scroll;

        & ul li > div {
            border-radius: 5px;
        }
        & ul li div.check {
            background: ${design.colorActive[0]};
            border-radius: 50%;
        }
        & ul li div.check svg g {
            fill: ${design.colorActive[2]};
        }
        & ul li div.glyph {
            font-size: 32px;
            line-height: ${32 * rule.line.height + 'px'};

            display: flex;
        }
    `;
    style.desktop = `
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: ${design.colorLayout[1]};
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: ${hexToRgba(design.colorLayout[1], 0.33)};
            border-radius: 5px;
            margin: 20px 0;
        } 
    `;
    style.mobile = `
        & ul {
            grid-template-columns: repeat(${value.grid.columns}, auto);
        }
        & ul li {
            width: ${value.item.size + 'px'};
            height: ${value.item.size + 'px'};
        }
    `;
    return style.general + style[client.platform];
});

// E X P O R T

export default function List(props) {

    // Asset
    const { action, active, client, design, layout } = props;
    const [designArray, designList] = {
        0: [designArray0, 'editorDesignList0'],
        1: [designArray1, 'editorDesignList1'],
        2: [designArray2, 'editorDesignList2']
    }[active.editorDesignMenu];
    // Calculate grid and item size for mobile
    const value = { grid: {}, item: {} };
    value.grid.length = designArray.length;
    value.grid.ratio = layout.editorToolRect.w / layout.editorToolRect.h;
    value.grid.rows = (
        value.grid.ratio > 2 ? 1 :
            value.grid.ratio > 1.2 ? 2 :
                value.grid.ratio > 0.6 ? 3 :
                    value.grid.ratio > 0.4 ? 4 : 5
    );
    value.grid.columns = Math.ceil(value.grid.length / value.grid.rows);
    value.item.size = (layout.editorToolRect.h - 50) / value.grid.rows - 15;

    // Component
    const Button = (props) => {
        const { index, input } = props;
        // Combine unique and global design properties
        const design = { ...props.design };
        for (let item in input) {
            delete input[item].name;
            for (let key in input[item]) {
                const keyName = camelize(item, key);
                const value = input[item][key];
                design[keyName] = value;
            }
        }
        // Stack color, image, and glyph
        const Check = () => active[designList] === index ? (
            <div className="check">
                <IconCheck />
            </div>
        ) : null;
        const Color = () => (
            <div className="color" style={{
                background: hexToGradient(design.colorDesign)
            }} />
        );
        const Glyph = () => (
            <div className="glyph">
                <Letter design={design} gross={3} index={0} input="A" />
                <Letter design={design} gross={3} index={1} input="B" />
                <Letter design={design} gross={3} index={2} input="C" />
            </div>
        );
        const Image = () => (
            <div className="image" style={{
                backgroundImage: `url(${design.themeImage})`
            }} />
        );
        // Apply unique design to each button
        return <li
            className={active[designList] === index ? 'active' : ''}
            onClick={() => action.set({ [designList]: index })}
            style={{
                color: design.colorLetter[0],
                fontFamily: design.fontFamily,
                fontStyle: design.fontStyle,
                fontWeight: design.fontWeight
            }}
        >
            <Check />
            <Color />
            <Glyph />
            <Image />
        </li>
    };

    // Render
    return <Styled id="editor-tool-list" client={client} design={design} value={value}>
        <ul>
            {designArray.map((item, key) => (
                <Button key={key} design={design} index={key} input={item} />
            ))}
        </ul>
    </Styled>;
};