// E X P O R T

export const splitObject = (object) => {
    // Break camelCase into separate keys
    const addKey = (object, key, value) => {
        if (/[A-Z]/.test(key)) {
            let oldKey, newKey, newObject;
            oldKey = key.match(/\w[a-z]+/)[0];
            newKey = key.match(/[A-Z]\w+/)[0];
            newKey = newKey.slice(0, 1).toLowerCase() + newKey.slice(1);
            // Add new keys to object
            object[oldKey] = { ...object[oldKey] };
            newObject = object[oldKey];
            addKey(newObject, newKey, value);
        } else {
            // Add value to final key
            object[key] = value;
        }
    }
    // Break down object one key at a time
    const newObject = {};
    for (let key in object) {
        const value = object[key];
        addKey(newObject, key, value);
    }
    return newObject;
};