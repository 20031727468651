// I M P O R T

// Library
import { hexToGradient } from '../library/function/color';
import { rule } from '../library/rule';

// Module
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Style
import '../style/canvas.css';

// S T Y L E D

const Styled = styled.div(props => {
    const { design, layout, parentId, canvasSize } = props;
    const { appSize, editorFormRect, renderSize } = layout;
    // Calculate position and size
    let position, size;
    if (parentId === 'app') {
        // Determize position relative to render
        const posX = editorFormRect.x + editorFormRect.w * rule.canvas.offset[0];
        const posY = editorFormRect.y + editorFormRect.h * rule.canvas.offset[1];
        position = [posX, posY];
        // Determine size relative to render
        const appScale = renderSize[0] / appSize[0];
        const editorScale = editorFormRect.w / appSize[0];
        size = (renderSize[0] * rule.canvas.scale) / appScale * editorScale;
    } else {
        const posX = canvasSize[0] * rule.canvas.offset[0];
        const posY = canvasSize[1] * rule.canvas.offset[1];
        position = [posX, posY];
        size = (canvasSize[0] * rule.canvas.scale);
    }
    return `
        & .canvas-color {
            background: ${hexToGradient(design.colorDesign)};
        }
        & .canvas-image {
            background: url(${design.themeImage});
            background-position: ${position[0] + 'px'} ${position[1] + 'px'};
            background-repeat: repeat;
            background-size: ${size + 'px'};
        }
    `;
});

// E X P O R T

export default function Canvas(props) {

    // Asset
    const { design, layout, parentId } = props;
    const canvasRef = useRef();

    // Scale canvas according to parent
    const [canvasSize, setCanvasSize] = useState([0, 0]);
    useEffect(() => {
        const parentSize = layout[parentId + 'Size'];
        setCanvasSize([parentSize[0], parentSize[1]]);
    }, [layout, parentId]);

    // Create canvas
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        let requestId;
        if (design.themeClass) {
            const objectArray = design.themeClass(design, canvasSize);
            const render = () => {
                context.clearRect(0, 0, ...canvasSize);
                objectArray.forEach(object => {
                    if (object.build) {
                        object.build(context);
                    } else if (object.animate) {
                        object.animate(context, rule.canvas.speed);
                        setAnimate(true);
                    }
                });
                if (animate) requestId = requestAnimationFrame(render);
            };
            requestAnimationFrame(render);
        }
        return () => {
            context.clearRect(0, 0, ...canvasSize);
            if (requestId) cancelAnimationFrame(requestId);
        }
    }, [animate, canvasSize, design]);

    // Render
    return <Styled className="canvas" {...props} canvasSize={canvasSize}>
        <canvas
            className="canvas-class"
            ref={canvasRef}
            width={canvasSize[0]}
            height={canvasSize[1]}
        />
        <div
            className="canvas-color"
            style={{
                width: canvasSize[0] + 'px',
                height: canvasSize[1] + 'px'
            }}
        />
        <div
            className="canvas-image"
            style={{
                width: canvasSize[0] + 'px',
                height: canvasSize[1] + 'px'
            }}
        />
    </Styled>;
};

