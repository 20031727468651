// E X P O R T

export const camelize = (...array) => {
    let string = array[0];
    for (let i = 1; i < array.length; i++) {
        string += array[i].slice(0, 1).toUpperCase() + array[i].slice(1);
    }
    return string;
};

export const capitalize = (string) => {
    const firstLetter = string.slice(0, 1);
    const isLowerCase = letter => /[a-z]/.test(letter);
    return (
        isLowerCase(firstLetter) ?
            firstLetter.toUpperCase() + string.slice(1) :
            firstLetter.toLowerCase() + string.slice(1)
    );
};

export const insertString = (string, substring, index) => (
    string.slice(0, (index[0] ? index[0] : index)) + substring +
    string.slice((index[1] ? index[1] : index), string.length)
);