// I M P O R T

// Library
import { stringToArray } from '../../library/function/array';
import { didUpdate } from '../../library/function/event';
import { insertString } from '../../library/function/string';
import { rule } from '../../library/rule';

// Module
import { createRef, PureComponent } from 'react';
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { design, safeSizeMax, safeSizeMin } = props;
    return `
        color: ${design.colorLetter[0]};
        font-family: ${design.fontFamily};
        font-style: ${design.fontStyle};
        font-weight: ${design.fontWeight};
    
        & div {
            box-shadow: 0 0 0 1px ${design.colorLetter[0]};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        & div.safe-max {
            width: ${safeSizeMax[0] + 'px'};
            height: ${safeSizeMax[1] + 'px'};
        }
        & div.safe-min {
            width: ${safeSizeMin[0] + 'px'};
            height: ${safeSizeMin[1] + 'px'};
        }
    `;
});

// E X P O R T

export default class Create extends PureComponent {

    // Construct
    constructor(props) {
        super(props);
        // Reference
        this.textRef = createRef();
        // State
        this.state = {
            areaSize: [0, 0],
            fontSize: 0,
            fontSizeMax: 0,
            safeSizeMax: [0, 0],
            safeSizeMin: [0, 0]
        };
    }

    // Effect
    componentDidMount() {
        this.configureForm();
    }
    componentDidUpdate(prevProps, prevState) {
        const props = [this.props, prevProps];
        const state = [this.state, prevState];
        // When area has been calculcated...
        didUpdate(...state, 'areaSize', this.configureText);
        // When editor exports text...
        didUpdate(...props, 'editor.textPlain', this.configureText);
        // When design changes...
        didUpdate(...props, 'design.fontFamily', this.configureText);
    }

    // Function
    configureForm = () => {
        // Configure Form
        // Cropped area serves as safety border for text
        const { layout } = this.props;
        const areaSize = [layout.mirrorSize[0], layout.mirrorSize[1]];
        const fontSizeMax = Math.floor(areaSize[0] * rule.font.sizeMax);
        const safeSizeMax = [
            Math.round(areaSize[0] * rule.safe.sizeMax[0] / 2) * 2,
            Math.round(areaSize[0] * rule.safe.sizeMax[1] / 2) * 2
        ];
        const safeSizeMin = [
            Math.round(areaSize[0] * rule.safe.sizeMin[0] / 2) * 2,
            Math.round(areaSize[0] * rule.safe.sizeMin[1] / 2) * 2
        ];
        this.setState({ areaSize, safeSizeMax, safeSizeMin, fontSizeMax });
    };
    configureText = () => {
        // Configure Text
        // Break text in lines and scale to size
        const { action, editor, mirror } = this.props;
        const { areaSize, fontSizeMax, safeSizeMax, safeSizeMin } = this.state;
        // Mark selection and separate words
        let selection = mirror.selection;
        let text = editor.textPlain || editor.quote;
        text = insertString(text, '&#8203;', selection);
        text = text.replace(/\r\n|\n|\r/g, ' ');
        const wordArray = stringToArray(text);

        // Break Text
        // Rebuild text word by word and add linebreaks
        let line, textArray, textRich;
        const breakText = () => {
            line = [];
            textArray = [];
            textRich = '';
            this.textRef.innerHTML = '';
            wordArray.forEach((word, index) => {
                // Find out whether text fits cropped area
                this.textRef.insertAdjacentHTML('beforeend', word);
                const tooWide = this.textRef.offsetWidth > safeSizeMax[0];
                // Gather additional data
                const isFinal = index === wordArray.length - 1;
                const isSpace = word === ' ';
                const newLine = line.length < 1;
                // Fill line until full
                if (!tooWide || newLine) {
                    line.push(word.replace('&#8203;', ''));
                    textRich += word;
                }
                else if (!isSpace) {
                    // Prevent buildup of spaces
                    if (textRich.slice(-1) === ' ') {
                        line.pop();
                        textRich = textRich.slice(0, -1);
                    }
                    // Push existing line, start a new line
                    textArray.push(line);
                    line = [word.replace('&#8203;', '')];
                    textRich += '\n' + word;
                    this.textRef.innerHTML = word;
                }
                // Push final line, finish up
                if (isFinal) {
                    textArray.push(line);
                    this.textRef.innerHTML = textRich;
                }
            });
        };

        // Scale Text
        let lineHeight;
        const scaleText = () => {
            lineHeight = Math.round(fontSize * rule.line.height);
            this.textRef.style.fontSize = fontSize + 'px';
            this.textRef.style.lineHeight = lineHeight + 'px';
        };

        // Build Text
        let fontSize, textSize;
        fontSize = this.state.fontSize || fontSizeMax;
        const buildText = () => {
            const resizeFont = (increment) => {
                fontSize += increment;
                scaleText();
                breakText();
                textSize = [this.textRef.offsetWidth, this.textRef.offsetHeight];
            }
            if (textRich === undefined) { resizeFont(0); }
            while (
                (textSize[0] < safeSizeMin[0] || textSize[1] < safeSizeMin[1]) &&
                (textSize[0] <= safeSizeMax[0] && textSize[1] <= safeSizeMax[1]) &&
                fontSize < fontSizeMax
            ) { resizeFont(1); }
            while (
                (textSize[0] > safeSizeMax[0] || textSize[1] > safeSizeMax[1]) &&
                fontSize > 1
            ) { resizeFont(-1); }
        };
        buildText();

        // Update State
        const fontSizeFactor = fontSize / areaSize[0];
        const rows = textArray.length;
        selection = textRich.indexOf('&#8203;');
        textRich = textRich.replace(/&#8203;/g, '');
        action.setAppState({
            editor: { textArray, textRich },
            mirror: { fontSizeFactor, rows, selection }
        });
        this.setState({ fontSize });
    };

    // Render
    render() {
        const { design } = this.props;
        return <Styled className="form" {...this.state} design={design}>
            <p ref={node => this.textRef = node} />
            <div className="safe-max" />
            <div className="safe-min" />
        </Styled>;
    }
}