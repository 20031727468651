// E X P O R T

export default function Image(props) {

    // Asset
    const { input, zIndex } = props;

    // Render
    return <div style={{
        backgroundImage: `url(${input})`,
        backgroundRepeat: 'repeat',
        backgroundSize: '100%',
        width: '100%',
        height: '100%',

        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: zIndex
    }} />;
};