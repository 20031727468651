// I M P O R T

import { DoubleSide } from 'three';

// E X P O R T

export const fixed = () => (
    <shaderMaterial attach="material" args={[{
        uniforms: {
            uTexture: { type: "t", value: null },
            uTime: { type: "f", value: 0 },
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = (
                    projectionMatrix *
                    modelViewMatrix *
                    vec4(
                        position,
                        1.0
                    )
                );
            }
        `,
        fragmentShader: `
            varying vec2 vUv;
            uniform sampler2D uTexture;
            void main() {
                vec2 uv = vUv;
                vec4 color = texture2D(uTexture, uv);
                gl_FragColor = color;
            }
        `
    }]} side={DoubleSide} />
);