// I M P O R T

// Asset
import placeholder from '../../asset/bitmap/placeholder.png';

// Library
import { rule } from '../../library/rule';

// Module
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';

// M E S H

const Mesh = (props) => {
    const { Geometry, Material, action, capture, render } = props;
    const meshRef = useRef();
    // Create texture from rendered image
    // If render is not available... Substitute with placeholder
    const image = render || placeholder;
    const imageType = render ? 'render' : 'placeholder';
    const texture = useLoader(THREE.TextureLoader, image);
    texture.minFilter = THREE.NearestFilter;
    // Apply texture to mesh
    useEffect(() => {
        const mesh = meshRef.current.material.uniforms;
        mesh.uTexture.type = imageType;
        mesh.uTexture.value = texture;
    });
    // Apply effects to mesh
    useFrame(({ camera, clock, gl, scene }) => {
        const mesh = meshRef.current.material.uniforms;
        mesh.uTime.value = clock.oldTime * rule.model.speed;
        if (capture && mesh.uTexture.type === 'render') {
            action.renderDesign(camera, gl, scene);
            action.setCapture(false);
        }
    });
    // Render
    return <mesh ref={meshRef}>
        <Geometry />
        <Material />
    </mesh>;
};

// E X P O R T

export default function Design(props) {

    // Asset
    const { action, active, design, render } = props;
    const canvasRef = useRef();

    // Effect
    const [capture, setCapture] = useState(false);
    useEffect(() => active.editorMenu === 2 ? setCapture(true) : null, [active]);

    // Function
    const renderDesign = useCallback((camera, gl, scene) => {
        gl.render(scene, camera);
        const canvas = canvasRef.current;
        const imageType = 'image/png';
        const imageData = canvas.toDataURL(imageType);
        action.setAppState({ render: { design: imageData } });
    }, [action]);

    // Render
    return <div>
        <Canvas ref={canvasRef} colorManagement>
            {/* Light */}
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {/* Geometry */}
            <Suspense fallback={null}>
                <Mesh
                    // Component
                    Geometry={design.modelGeometry}
                    Material={design.modelMaterial}
                    // Property
                    action={{ renderDesign, setCapture }}
                    capture={capture}
                    render={render.create}
                />
            </Suspense>
        </Canvas>
    </div>;
};