// I M P O R T

// Component
import Form from './Form';
import Menu from './Menu';
import Tool from './Tool';

// Style
import '../../style/editor.css';
import '../../style/editor-form.css';
import '../../style/editor-menu.css';
import '../../style/editor-tool.css';
import '../../style/editor-tool-menu.css';
import '../../style/editor-tool-list.css';

// E X P O R T

export default function Editor(props) {

    // Render
    return <div id="editor">
        <Form {...props} />
        <Menu {...props} />
        <Tool {...props} />
    </div>;
};