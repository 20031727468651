// I M P O R T

// Library
import { randomEntry } from "./function/random";

// E X P O R T

export const quoteStore = [{
    active: true,
    author: `Harper Lee`,
    source: `To Kill a Mockingbird`,
    content:
        `Real courage is when you know you're licked before you begin, but you begin anyway and see it through no matter what.`
}, {
    active: true,
    author: `Isaac Asimov`,
    source: `Foundation`,
    content:
        `If you're born in a cubicle and grow up in a corridor, and work in a cell then coming up into the open with nothing but sky over you might just give you a nervous breakdown.`
}, {
    active: true,
    author: `Douglas Adams`,
    source: `The Hitchhiker's Guide to the Galaxy`,
    content:
        `Isn't it enough to see that a garden is beautiful without having to believe that there are fairies at the bottom of it too?`
}, {
    active: true,
    author: `Carlo Collodi`,
    source: `Pinocchio`,
    content:
        `What matters school? We can go to school tomorrow. Whether we have a lesson more or a lesson less, we shall always remain the same donkeys.`
}, {
    active: true,
    author: `George Orwell`,
    source: `1984`,
    content:
        `I enjoy talking to you. Your mind appeals to me. It resembles my own mind except that you happen to be insane.`
}, {
    active: true,
    author: `J. R. R. Tolkien`,
    source: `The Lord of the Rings`,
    content:
        `"Don't the great tales never end?” “No, they never end as tales. But the people in them come, and go when their part's ended. Our part will end later, or sooner."`
}, {
    active: true,
    author: `John Carpenter`,
    source: `They Live`,
    content:
        `The poor and the underclass are growing. Racial justice and human rights are nonexistent. They have created a repressive society and we are their unwitting accomplices.`
}, {
    active: true,
    author: `Warren Spector`,
    source: `Deus Ex`,
    content:
        `Somehow the notion of unalienable liberty got lost. It's really become a question of what liberties will the state assign to individuals or rather, what liberties we will have the strength to cling to.`
}, {
    active: true,
    author: `Michael Crighton`,
    source: `Jurassic Park`,
    content:
        `Let's be clear. The planet is not in jeopardy. We are in jeopardy. We haven't got the power to destroy the planet, or to save it. But we might have the power to save ourselves.`
}, {
    active: true,
    author: `Friedrich Nietzsche`,
    source: `Beyond Good and Evil`,
    content:
        `Sensuality often makes love grow too quickly, so that the root remains weak and is easy to pull out.`
}, {
    active: true,
    author: `Plato`,
    source: `The Republic`,
    content:
        `"But does a just man do harm to anyone?" "Oh yes," he replied: "One ought to harm bad men who are our enemies." "If we harm a horse, do we make it better or worse?"`
}, {
    active: true,
    author: `Ken Levine`,
    source: `BioShock Infinite`,
    content:
        `One man goes into the waters of baptism, a different man comes out. But who is that man who lies submerged? Perhaps that swimmer is both sinner and saint, until he is revealed unto the eyes of man.`
}, {
    active: true,
    author: `Tonke Dragt`,
    source: `A Letter for the King`,
    content:
        `As long as you remember that fighting evil doesn't necessarily make you good! Good and evil are each other's enemies, but they are often found close together.`
}, {
    active: true,
    author: `Gene Roddenberry`,
    source: `Star Trek: The Next Generation`,
    content:
        `History has proven again and again that whenever mankind interferes with a less developed civilization, no matter how well intentioned that interference may be, the results are invariably disastrous.`
}, {
    active: true,
    author: `H. P. Lovecraft`,
    source: `The Call of Cthulhu`,
    content:
        `We live on a placid island of ignorance in the midst of black seas of infinity, and it was not meant that we should voyage far.`
}, {
    active: true,
    author: `Philip Kaufman`,
    source: `Invasion of the Body Snatchers`,
    content:
        `I keep seeing these people, all recognizing each other. Something is passing between them all, some secret. It's a conspiracy, I know it.`
}, {
    active: true,
    author: `Harlan Ellison`,
    source: `I Have No Mouth and I Must Scream`,
    content:
        `A man does not write one novel at a time. He is on a journey and he is reporting in: “This is where I think I am and this is what this place looks like today."`
}, {
    active: true,
    author: `L. Frank Baum`,
    source: `The Wonderful Wizard of Oz`,
    content:
        `I think you are wrong to want a heart. It makes most people unhappy. If you only knew it, you are in luck not to have a heart.`
}, {
    active: true,
    author: `Joe Haldeman`,
    source: `The Forever War`,
    content:
        `The 1143-year-long war had begun on false pretenses. Once they could talk, the first question was: “Why did you start this thing?”, and the answer was: “Me?”.`
}, {
    active: true,
    author: `John Steinbeck`,
    source: `Of Mice and Men`,
    content:
        `Guy don't need no sense to be a nice fella. Seems to me sometimes it jus' works the other way around. Take a real smart guy and he ain't hardly ever a nice fella.`
}, {
    active: true,
    author: `Anthony Burgess`,
    source: `A Clockwork Orange`,
    content:
        `It's funny how the colors of the real world only seem really real when you watch them on a screen.`
}, {
    active: true,
    author: `Irwin Shaw`,
    source: `Short Stories of Irwin Shaw`,
    content:
        `I look at everything. God gave me eyes and I look at women and men and subway excavations and moving pictures and the little flowers of the field. I casually inspect the universe.`
}, {
    active: true,
    author: `Aldous Huxley`,
    source: `Brave New World`,
    content:
        `But I don't want comfort. I want God, I want poetry, I want real danger, I want freedom, I want goodness. I want sin.`
}, {
    active: true,
    author: `J. R. R. Tolkien`,
    source: `The Hobbit`,
    content:
        `Do you wish me a good morning, or mean that it is a good morning whether I want it or not, or that you feel good this morning, or that it is a morning to be good on?`
}, {
    active: true,
    author: `Chuck Palahniuk`,
    source: `Haunted`,
    content:
        `The difference between how you look and how you see yourself is enough to kill most people. And maybe the reason vampires don't die is because they can never see themselves in photographs or mirrors.`
}, {
    active: true,
    author: `Frank Herbert`,
    source: `Dune`,
    content:
        `Any road followed precisely to its end leads precisely nowhere. Climb the mountain just a little bit to test that it's a mountain. From the top of the mountain, you cannot see the mountain.`
}, {
    active: true,
    author: `Robert A. Heinlein`,
    source: `Starship Troopers`,
    content:
        `The junior hoodlums who roamed their streets were symptoms of a greater sickness. Their citizens glorified their mythology of 'rights' and lost track of their duties.`
}, {
    active: true,
    author: `Shirley Jackson`,
    source: `We Have Always Lived in the Castle`,
    content:
        `We eat the year away. We eat the spring and the summer and the fall. We wait for something to grow and then we eat it.`
}, {
    active: true,
    author: `J. R. R. Tolkien`,
    source: `The Hobbit`,
    content:
        `"Where did you go to, if I may ask?" "To look ahead," said he. "And what brought you back in the nick of time?" "Looking behind," said he.`
}, {
    active: true,
    author: `C. S. Lewis`,
    source: `On Stories: And Other Essays on Literature`,
    content:
        `The child does not despise real woods because he has read of enchanted woods. The reading makes all real woods a little enchanted.`
}, {
    active: true,
    author: `Robert A. Heinlein`,
    source: `Time Enough For Love`,
    content:
        `Don't ever become a pessimist. A pessimist is correct oftener than an optimist but an optimist has more fun, and neither can stop the march of events.`
}, {
    active: true,
    author: `Susanna Rowson`,
    source: `Charlotte Temple`,
    content:
        `Be thankful for the good, be patient under the evil, and presume not to enquire why the latter predominates.`
}];

export const quoteArray = quoteStore.filter(quote => quote.active);

export const quote = randomEntry(quoteArray);