// I M P O R T

import { rule } from '../../../rule';

// E X P O R T

export const plane = () => (
    <planeBufferGeometry args={[
        rule.model.scale * 6.5,
        rule.model.scale * 6.5,
        rule.model.scale * 32
    ]} />
);