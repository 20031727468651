// I M P O R T

// Component
import Create from './Create';
import Design from './Design';
import Export from './Export';
import Figure from './Figure';

// Module
import styled from 'styled-components';

// Style
import '../../style/render.css';

// S T Y L E D

const Styled = styled.div(props => {
    const { design, layout } = props;
    return `
        position: absolute;
        top: 80px;
        right: 10px;
        z-index: 99;

        & > div {
            box-shadow: 0 0 0 1px ${design.colorLetter[0]};
            width: ${layout.renderSize[0] + 'px'};
            height: ${layout.renderSize[1] + 'px'};

            position: relative;
        }
    `;
});

// E X P O R T

export default function Render(props) {

    // Asset
    const { design, layout } = props;

    // Render
    return (
        <Styled id="render" design={design} layout={layout}>
            <Create {...props} />
            <Design {...props} />
            <Export {...props} />
            <Figure {...props} />
        </Styled>
    );
};