// I M P O R T

// Element
import Word from '../../element/Word';

// Library
import { rule } from '../../library/rule';

// Module
import { useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { design, value } = props;
    return `
        font-family: ${design.fontFamily};
        font-size: ${value.fontSize + 'px'};
        font-style: ${design.fontStyle};
        font-weight: ${design.fontWeight};
        line-height: ${value.lineHeight + 'px'};

        & .line {
            display: flex;
            flex-direction: row;
            justify-content: center;

            position: relative;
        }
    `;
});

export default function Create(props) {

    // Asset
    const { action, active, design, editor, layout, mirror, render } = props;
    const value = {};
    value.fontSize = Math.floor(layout.renderSize[0] * mirror.fontSizeFactor);
    value.lineHeight = Math.round(value.fontSize * rule.line.height);
    value.wordGross = 0;
    const canvasRef = useRef();

    // Effect
    const [capture, setCapture] = useState(false);
    useEffect(() => active.editorMenu > 0 ? setCapture(true) : null, [active]);
    useEffect(() => {
        if (
            capture &&
            render.create === null
        ) {
            setCapture(false);
            htmlToImage
                .toPng(canvasRef.current)
                .then(imageData => (
                    action.setAppState({ render: { create: imageData } })
                ));
        }
    }, [action, capture, render]);

    // Component
    const Text = (props) => {
        const lineArray = props.input;
        const wordArray = [];
        return <div className="text">
            {lineArray.map((line, key) => {
                const gross = wordArray.length;
                wordArray.push(...line);
                value.wordGross = wordArray.length;
                return <Line key={key} gross={gross} input={line} />
            })}
        </div>;
    };
    const Line = (props) => {
        const wordArray = props.input;
        return <div className="line">
            {wordArray.map((word, key) => {
                const gross = value.wordGross;
                const index = props.gross + key;
                if (word === ' ') return <Space key={key} />
                else return <Word
                    // Attribute
                    noScale
                    noShift
                    noSlant
                    // Content
                    key={key}
                    // Property
                    design={design}
                    gross={gross}
                    index={index}
                    input={word}
                />
            })}
        </div>;
    };
    const Space = (props) => {
        return <div className="space">
            &nbsp;
        </div>;
    };

    // Render
    return <Styled className="form" ref={canvasRef} design={design} value={value}>
        <Text input={editor.textArray} />
    </Styled>;
};