// I M P O R T

// Element
import Canvas from '../../element/Canvas';

// Module
import { useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

// E X P O R T

export default function Figure(props) {

    // Asset
    const { action, active, render } = props;
    const canvasRef = useRef();

    // Effect
    const [capture, setCapture] = useState(false);
    useEffect(() => active.editorMenu === 2 ? setCapture(true) : null, [active]);
    useEffect(() => {
        if (
            capture &&
            render.figure === null
        ) {
            setCapture(false);
            htmlToImage
                .toPng(canvasRef.current)
                .then(imageData => (
                    action.setAppState({ render: { figure: imageData } })
                ));
        }
    }, [action, capture, render]);

    // Render
    return <div ref={canvasRef}>
        <Canvas {...props} parentId="render" />
    </div>;
};