// E X P O R T

export const rule = {
    app: {
        publish: true,
    },
    canvas: {
        offset: [0.5, 0.5],
        scale: 1,
        speed: 1
    },
    font: {
        sizeMax: 0.15
    },
    line: {
        height: 1.2
    },
    model: {
        scale: 1,
        speed: 0.0005
    },
    safe: {
        sizeMax: [0.85, 0.75],
        sizeMin: [0.75, 0.65]
    }
};