// I M P O R T

// Asset Geometry
import { box } from './geometry/box';
import { plane } from './geometry/plane';
import { sphere } from './geometry/sphere';

// Asset Material
import { fixed } from './material/fixed';
import { wave } from './material/wave';

// Library
import { randomEntry } from '../../function/random';

// E X P O R T

export const modelArray = [{
    name: 'flat',
    geometry: box,
    material: fixed
}, {
    name: 'flag',
    geometry: plane,
    material: wave
}, {
    name: 'ball',
    geometry: sphere,
    material: fixed
}];

export const model = randomEntry(modelArray);