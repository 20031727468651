// I M P O R T

// Module
import styled from 'styled-components';

// Style
import '../style/status.css';

// S T Y L E D

const Styled = styled.div(props => {
    const { design } = props;
    return `
        color: ${design.colorLetter[0]};

        position: absolute;
        top: 80px;
        left: 10px;
        z-index: 99;

        & figure {
            box-shadow: inset 0 0 0 1px ${design.colorLetter[0]};
        }
    `;
});

// E X P O R T

export default function Status(props) {

    // Asset
    const { design } = props;

    // Component
    const Color = props => <figure style={{ background: props.value }} />;

    // Function
    const printState = () => {
        const categoryArray = [];
        for (let key in props) {
            const category = printCategory(props[key]);
            categoryArray.push({ content: category, name: key });
        }
        return categoryArray.map((category, index) => (
            <ul aria-label={category.name} key={index}>
                {category.content}
            </ul>
        ));
    };
    const printCategory = (category) => {
        const itemArray = [];
        for (let key in category) {
            const item = printItem(category[key]);
            itemArray.push({ content: item, name: key });
        }
        return itemArray.map((item, index) => (
            <li key={index}>
                {item.name}: {item.content}
            </li>
        ));
    };
    const printItem = (item) => {
        const parseValue = (value) => {
            if (value === null) return 'null'
            if (value === undefined) return 'undefined'
            if (typeof value === 'number') return value
            if (typeof value === 'string') {
                return value[0] === '#' ? <Color value={value} /> : value
            }
            if (typeof value === 'boolean') return value ? 'true' : 'false'
            if (typeof value === 'function') return value.name
            if (Array.isArray(value)) return 'array'
            if (typeof value === 'object') return 'object'
            return 'error';
        }
        if (Array.isArray(item)) {
            if (item.length && item[0][0] === '#') {
                return item.map((value, key) => <Color value={value} key={key} />);
            } else {
                const valueArray = [];
                item.map(value => valueArray.push(parseValue(value)));
                return `[${valueArray.join(', ')}]`;
            }
        } else if (item !== (null && undefined) && typeof item === 'object') {
            const entries = Object.entries(item);
            return `{${entries.map(entry => {
                const [key, value] = [entry[0], parseValue(entry[1])];
                return ` ${key}:${value}`;
            })} }`;
        } else {
            return parseValue(item);
        }
    };

    // Render
    return <Styled id="status" design={design}>
        {printState()}
    </Styled>;
};