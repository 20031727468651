// I M P O R T

// Library
import { hexToRgba } from '../../library/function/color';
import { didUpdate } from '../../library/function/event';
import { rule } from '../../library/rule';

// Module
import { createRef, PureComponent } from 'react';
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.form(props => {
    const { design, value } = props;
    return `
        & textarea {
            color: ${design.colorLetter[0]};
            font-family: ${design.fontFamily};
            font-size: ${value.fontSize + 'px'};
            font-style: ${design.fontStyle};
            font-weight: ${design.fontWeight};
            line-height: ${value.lineHeight + 'px'};
        }
        & textarea::placeholder {
            color: ${hexToRgba(design.colorActive[0], 0.3)};
        }
        & textarea::selection {
            background: ${hexToRgba(design.colorActive[2], 0.6)};
        }
    `;
});

// E X P O R T

export default class Draft extends PureComponent {

    // Construct
    constructor(props) {
        super(props);
        // Reference
        this.textRef = createRef();
    }

    // Effect
    componentDidMount() {
        this.focus();
    }
    componentDidUpdate(prevProps, prevState) {
        const props = [this.props, prevProps];
        // When mirror has processed text...
        didUpdate(...props, 'editor.textRich', this.focus);
        // When user changes editor panel...
        didUpdate(...props, 'active.editorMenu', () => (
            this.props.active.editorMenu === 0 ? this.focus() : this.setFocus()
        ));
    }

    // Function
    addKey = (event) => (
        event.key === 'Enter' ? event.preventDefault() : null
    );
    addText = (event) => {
        const { action, active } = this.props;
        if (active.editorMenu === 0) {
            const textPlain = event.target.value;
            const selection = this.textRef.selectionStart || textPlain.length;
            action.setAppState({
                editor: { textPlain },
                mirror: { selection }
            });
        }
    };
    focus = () => {
        const { mirror } = this.props;
        const selection = mirror.selection || this.textRef.value.length;
        this.textRef.selectionStart = selection;
        this.textRef.selectionEnd = selection;
        this.textRef.focus();
    };
    setFocus = () => {
        const { action, editor } = this.props;
        const selection = this.textRef.selectionStart || editor.textPlain.length;
        action.setAppState({ mirror: { selection } });
    };

    // Render
    render() {
        const { design, editor, layout, mirror, style } = this.props;
        const value = {};
        value.fontSize = (editor.textPlain === '' ?
            Math.floor(layout.editorFormRect.w * rule.font.sizeMax) :
            Math.floor(layout.editorFormRect.w * mirror.fontSizeFactor)
        );
        value.lineHeight = Math.round(value.fontSize * rule.line.height);

        return <Styled className="form" design={design} style={style} value={value}>
            <textarea
                // Atribute
                autoComplete="on"
                autoCorrect="off"
                rows={editor.textPlain ? mirror.rows : 1}
                spellCheck="false"
                // Content
                placeholder={editor.placeholder}
                ref={node => this.textRef = node}
                value={editor.textPlain ? editor.textRich : ''}
                // Function
                onKeyDown={event => this.addKey(event)}
                onChange={event => this.addText(event)}
            />
        </Styled>;
    }
};