// I M P O R T

// Asset
import placeholder from '../../asset/bitmap/placeholder.png';

// Element
import Image from '../../element/Image';

// Module
import { useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

// E X P O R T

export default function Export(props) {

    // Asset
    const { action, active, render } = props;
    const canvasRef = useRef();

    // Effect
    const [capture, setCapture] = useState(false);
    useEffect(() => active.editorMenu === 2 ? setCapture(true) : null, [active]);
    useEffect(() => {
        if (
            capture &&
            render.design !== null &&
            render.figure !== null &&
            render.export === null
        ) {
            setCapture(false);
            htmlToImage
                .toPng(canvasRef.current)
                .then(imageData => (
                    action.setAppState({ render: { export: imageData } })
                ));
        }
    }, [action, capture, render]);

    // Render
    return <div ref={canvasRef}>
        <Image input={render.design || placeholder} zIndex={1} />
        <Image input={render.figure || placeholder} zIndex={0} />
    </div>;
};