// I M P O R T

// Asset
import { IconColor, IconFont, IconModel } from '../../asset/vector/icon';

// Library
import { hexToRgba } from '../../library/function/color';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client, design } = props;
    const style = {};
    style.general = `
        & ul li svg g {
            fill: ${design.colorActive[1]};
        }
        & ul li.active svg g {
            fill: ${design.colorActive[0]};
        }
    `;
    style.desktop = `
        box-shadow: 0 1px ${hexToRgba(design.colorLayout[2], 0.10)};
    `;
    return style.general + style[client.platform];
});

// E X P O R T

export default function List(props) {

    // Asset
    const { action, active, client, design } = props;

    // Component
    const Button = (props) => {
        const { index, name } = props;
        const Icon = () => ({
            "color": <IconColor className="icon" />,
            "font": <IconFont className="icon" />,
            "model": <IconModel className="icon" />
        }[name]);
        return <li
            className={active.editorDesignMenu === index ? 'active' : ''}
            onClick={() => action.set({ editorDesignMenu: index })}
        >
            <Icon />
        </li>;
    };

    // Render
    return <Styled id="editor-tool-menu" client={client} design={design}>
        <ul>
            <Button index={0} name="color" />
            <Button index={1} name="font" />
            <Button index={2} name="model" />
        </ul>
    </Styled>;
};