// I M P O R T

// Library
import { degreeToCos } from '../../function/geometry';

// E X P O R T

export const decal = {
    default: () => {
        const w = 0, h = 0, r = 0, s = [0, 0], x = 0, y = 0;
        return { w, h, r, s, x, y};
    },
    strike: (factor = 0.25) => {
        const w = degreeToCos(factor / 10 * 360, 0.5) * 80 + 20;
        const h = 14;
        const r = factor / 10;
        const s = [1, 1];
        const x = (100 - w) / 2;
        const y = 100 * 0.5 - h * 0.5;
        return { w, h, r, s, x, y};
    },
    strikeFlip: (factor = -0.25) => {
        return decal.strike(factor);
    },
    stroke: (factor = 0.25) => {
        const w = 100;
        const h = 50 * factor;
        const r = 0;
        const s = [1, 1];
        const x = (100 - w) / 2;
        const y = 100 * 0.5 - h * 0.5;
        return { w, h, r, s, x, y};
    },
    strokeThick: (factor = 0.5) => {
        return decal.stroke(0.5);
    },
    strokeThin: () => {
        return decal.stroke(0.125);
    },
    underline: (factor = 0.25) => {
        const w = 100;
        const h = 50 * factor;
        const r = 0;
        const s = [1, 1];
        const x = 0;
        const y = 93;
        return { w, h, r, s, x, y};
    }
};