// I M P O R T

// Library
import { hexToRgba } from "../../../function/color";
import { randomInteger } from "../../../function/random";

// E X P O R T

export const aquarel = (design, designSize) => {

    // Construct
    class Circle {
        constructor(color, direction, position, radius, sin) {
            this.color = color;
            this.designSize = designSize;
            this.direction = direction;
            this.position = position;
            this.radius = radius;
            this.sin = sin;
        }
        animate(context, speed) {
            // Move forward
            this.position[0] += this.direction[0] * speed;
            this.position[1] += this.direction[1] * speed;
            this.radius += Math.sin(this.sin) * speed;
            this.sin += 0.01;
            // Bounce off sides
            if (this.position[0] < 0) {
                this.direction[0] *= -1;
                this.position[0] += 10;
            }
            else if (this.position[0] > this.designSize[0]) {
                this.direction[0] *= -1;
                this.position[0] -= 10
            }
            if (this.position[1] < 0) {
                this.direction[1] *= -1;
                this.position[1] += 10;
            }
            else if (this.position[1] > this.designSize[1]) {
                this.direction[1] *= -1;
                this.position[1] -= 10;
            }
            // Draw shape
            context.beginPath();
            context.globalCompositeOperation = 'saturation';
            const gradient = context.createRadialGradient(
                this.position[0],
                this.position[1],
                this.radius * 0.01,
                this.position[0],
                this.position[1],
                this.radius
            );
			gradient.addColorStop(0, `${hexToRgba(this.color, 1)}`);
			gradient.addColorStop(1, `${hexToRgba(this.color, 0)}`);
			context.arc(
                this.position[0],
                this.position[1],
                this.radius,
                0,
                Math.PI * 2,
                false
            );
			context.fillStyle = gradient;
			context.fill();
        }
    }

    // Render
    const array = [];
    const count = 20;
    for (let i = 1; i < count; i++) {
        const color = design.colorDesign[i % design.colorDesign.length];
        const direction = [randomInteger(-4, 4), randomInteger(-4, 4)];
        const position = [Math.random() * designSize[0], Math.random() * designSize[1]];
        const radius = randomInteger(designSize[1] * 0.33, designSize[1] * 0.67);
        const sin = Math.random();
        array.push(new Circle(color, direction, position, radius, sin));
    }
    return array;
};