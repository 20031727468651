// I M P O R T

import { rule } from '../../../rule';

// E X P O R T

export const sphere = () => (
    <boxBufferGeometry args={[
        rule.model.scale * 4.35,
        rule.model.scale * 4.35,
        rule.model.scale * 4.35
    ]} />
);