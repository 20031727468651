// E X P O R T

export const scale = {
    default: () => {
        return [1, 1];
    },
    horizontal: (factor = 0.25) => {
        const x = 1 + 0.5 * (Math.random() * 10 * factor - 5 * factor);
        const y = 1;
        return [x, y];
    },
    random: (factor = 0.25) => {
        const a = 1 + Math.random() * 2.5 * factor - 1.25 * factor;
        return [a, a];
    },
    vertical: (factor = 0.25) => {
        const x = 1;
        const y = 1 + 0.5 * (Math.random() * 10 * factor - 5 * factor);
        return [x, y];
    },
    zigzag: (factor = 0.25, gross, index) => {
        const a = 1 + (index % 2 * 2 - 1) * factor;
        return [a, a];
    }
};