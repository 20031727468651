// E X P O R T

export const countArray = (array) => {
    let length = 0;
    const count = (array) => array.forEach(item =>
        Array.isArray(item) ? count(item) : length++
    );
    count(array);
    return length;
};

export const arrayToString = (array) => {
    let string = '';
    if (typeof array[0] === 'string') string += array.join('')
    if (Array.isArray(array[0])) array.forEach((item, index) => {
        if (index === array.length - 1) string += arrayToString(item)
        else string += arrayToString(item) + '\r'
    })
    return string;
};

export const stringToArray = (string) => {
    return string.match(/\S+|\s/g);
};