// I M P O R T

// Library
import { randomEntry } from '../function/random';

// E X P O R T

export const colorArray = [{
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#f0f0f0'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#ff5788', '#ffd035', '#81e2de', '#3cbbff'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#7fd5ff'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#abd936'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#ffdc31'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#afefe5'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#ff97b5'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#757aff'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a', '#b858dc', '#ff63ff', '#ffcf50'],
    shadow: null
}, {
    name: 'default',
    active: ['#1c1e7a', '#cbd3df', '#ffffff'],
    design: ['#fc9149'],
    layout: ['#ffffff', '#cbd3df', '#21232d'],
    letter: ['#1c1e7a'],
    shadow: null
}];

export const color = randomEntry(colorArray);