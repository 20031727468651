// I M P O R T

// Asset
import { color } from './color';
import { decal } from './decal';
import { scale } from './scale';
import { shift } from './shift';
import { slant } from './slant';

// Library
import { randomEntry } from '../../function/random';

// E X P O R T

export const warpArray = [{
    name: 'default',
    color: color.default,
    decal: decal.default,
    scale: scale.default,
    shift: shift.default,
    slant: slant.default,
}, {
    name: 'random',
    color: color.random,
    decal: decal.default,
    scale: scale.vertical,
    shift: shift.random,
    slant: slant.zigzag,
}, {
    name: 'gradient',
    color: color.default,
    decal: decal.underline,
    scale: scale.default,
    shift: shift.default,
    slant: slant.default,
}];

export const warp = randomEntry(warpArray);