// I M P O R T

// Component
import List from './ToolDesignList';
import Menu from './ToolDesignMenu';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client } = props;
    const style = {};
    style.general = `
        height: 100%;
        display: flex;
    `;
    style.desktop = `
        flex-direction: column;
    `;
    style.mobile = `
        flex-direction: column-reverse;
    `;
    return style.general + style[client.platform];
});

// E X P O R T

export default function Tool(props) {

    // Asset
    const { client, style } = props;

    // Render
    return <Styled client={client} style={style}>
        <Menu {...props} />
        <List {...props} />
    </Styled>;
};