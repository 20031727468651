// I M P O R T

// Library
import { blendHex } from '../../function/color';

// E X P O R T

export const color = {
    default: (color) => {
        return color[0];
    },
    gradient: (color, gross = 1, index = 0) => {
        const colorIndex = index / (gross - 1) * (color.length - 1) || 0;
        const hex1 = color[Math.floor(colorIndex)];
        const hex2 = color[Math.ceil(colorIndex)];
        const step = (gross - 1) / (color.length - 1);
        const bias = (index % step / step || 0).toFixed(2);
        return blendHex(hex1, hex2, bias)
    },
    random: (color) => {
        return color[Math.floor(Math.random() * color.length)];
    }
};