// I M P O R T

// Asset
import { IconCreate, IconDesign, IconExport } from '../../asset/vector/icon';
import { ShapeBalloonStroke, ShapeBubbleStroke } from '../../asset/vector/shape';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { design } = props;
    return `
        height: 100%;
        
        position: relative;

        & ul {
            padding: 0;
            width: 100%;

            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            list-style-type: none;

            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        & ul li {
            color: ${design.colorActive[1]};
            font-family: 'Nunito', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
        & ul li .glyph {
            width: 24px;
            height: 24px;

            display: inline-block;

            margin: 0 8px 0 0;
            position: relative;
            top: 6px;
        }
        & ul li .glyph svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        & ul li .glyph svg g {
            fill: ${design.colorActive[1]};
        }
        & ul li .glyph svg.icon {
            width: 53.333%;
        }
        & ul li .glyph svg.shape {
            width: 100%;
        }
    `;
});

// E X P O R T

export default function Create(props) {

    // Asset
    const { design, style } = props;

    // Component
    const Glyph = (props) => {
        const { name } = props;
        const Icon = {
            'create': IconCreate,
            'design': IconDesign,
            'export': IconExport
        }[name];
        const Shape = {
            'true': ShapeBalloonStroke,
            'false': ShapeBubbleStroke
        }[name === 'create'];
        return <div className="glyph">
            <Icon className="icon" />
            <Shape className="shape" />
        </div>
    };

    // Render
    return <Styled design={design} style={style}>
        <ul>
            <li><Glyph name="create" />Write a message</li>
            <li><Glyph name="design" />Choose a design</li>
            <li><Glyph name="export" />Download</li>
        </ul>
    </Styled>;
};