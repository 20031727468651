// I M P O R T

// Component
import Create from './FormCreate';
import Design from './FormDesign';
import Export from './FormExport';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client, design } = props;
    const style = {};
    style.desktop = `
        border-radius: 15px;
        box-shadow: inset 0 0 0 10px ${design.colorLayout[0]};
    `;
    return style[client.platform];
});

// E X P O R T

export default function Tool(props) {

    // Asset
    const { client, design } = props;
    const active = props.active.editorMenu;
    const style = { display: 'none', pointerEvents: 'none' };

    // Render
    return <Styled id="editor-form" client={client} design={design}>
        <Create {...props} style={active === 0 ? null : style} />
        <Design {...props} style={active === 1 ? null : style} />
        <Export {...props} style={active === 2 ? null : style} />
    </Styled>;
};