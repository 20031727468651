// I M P O R T

// Asset
import { ReactComponent as LogoName } from '../asset/vector/logo/name.svg';
import { ReactComponent as IconMenu } from '../asset/vector/icon/menu.svg';

// Library
import { hexToRgba } from '../library/function/color';

// Module
import styled from 'styled-components';

// Style
import '../style/header.css';

// S T Y L E D

const Styled = styled.header(props => {
    const { design } = props;
    return `
        background: ${design.colorLayout[0]};
        box-shadow: 0 2px 2px ${hexToRgba(design.colorLayout[2], 0.3)};

        & svg g {
            fill: ${design.colorLetter[0]};
        }
    `;
})

// E X P O R T

export default function Header(props) {

    // Asset
    const { design } = props;

    // Render
    return <Styled design={design}>
        <LogoName />
        <IconMenu />
    </Styled>;
};