// E X P O R T

export const randomBoolean = (chance = 0.5) => {
    return Math.random() < chance;
};

export const randomEntry = (object) => {
    if (Array.isArray(object)) {
        return object[Math.floor(Math.random() * object.length)];
    } else {
        const keys = Object.keys(object);
        return object[keys[Math.random() << 0 * keys.length]];
    }
};

export const randomInteger = (min = 0, max = 10) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};