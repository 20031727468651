// I M P O R T

// Library
import { designArray0, designArray1, designArray2 } from '../library/design';
import { addListener, removeListener, didUpdate } from '../library/function/event';
import { camelize } from '../library/function/string';
import { debounce } from '../library/function/time';

// Module
import { PureComponent } from 'react';

// E X P O R T

export default class Worker extends PureComponent {

    // Effect
    componentDidMount() {
        this.measureApp();
        this.updateDesign();
        addListener(window, ['resize'], this.resizeApp);
    }
    componentDidUpdate(prevProps, prevState) {
        const props = [this.props, prevProps];
        didUpdate(...props, 'active.editorDesignList0', () => this.updateDesign(0));
        didUpdate(...props, 'active.editorDesignList1', () => this.updateDesign(1));
        didUpdate(...props, 'active.editorDesignList2', () => this.updateDesign(2));
        didUpdate(...props, 'active.editorMenu', () => {
            const active = this.props.active.editorMenu;
            if (active < 2) this.clearRender('design', 'export', 'figure');
            if (active < 1) this.clearRender('create');
        });
        didUpdate(...props, 'design', () => this.clearRender('create'));
    }
    componentWillUnmount() {
        removeListener(window, ['resize'], this.resizeApp);
    }

    // Measure components and define platform and viewport
    measureApp = () => {
        const getRect = (id) => {
            const ref = document.getElementById(id);
            if (ref !== null) {
                const { x, y, width: w, height: h } = ref.getBoundingClientRect();
                return { x, y, w, h };
            } else return {}
        };
        const getSize = (id) => {
            const ref = document.getElementById(id);
            if (ref !== null) {
                const rect = ref.getBoundingClientRect();
                return [rect.width, rect.height];
            } else return []
        };
        // Measure variety of elements
        const appSize = getSize('app');
        const editorFormRect = getRect('editor-form');
        const editorToolRect = getRect('editor-tool');
        // Determine client device
        const platform = appSize[0] > 700 ? 'desktop' : 'mobile';
        const rotation = (
            appSize[0] > appSize[1] ? 'horizontal' :
                appSize[0] < appSize[1] ? 'vertical' : 'square'
        );
        // Return state
        this.props.action.setAppState({
            client: { platform, rotation },
            layout: { appSize, editorFormRect, editorToolRect }
        });
    };
    resizeApp = debounce(() => this.measureApp(), 50);

    // Apply changes in design to state
    updateDesign = (number) => {
        // Collect design from library
        const getDesign = (number) => {
            const active = this.props.active['editorDesignList' + number];
            const designArray = {
                0: designArray0, 1: designArray1, 2: designArray2
            }[number];
            return designArray[active];
        };
        // Format design for export
        const nextDesign = {};
        const setDesign = (design) => {
            for (let group in design) {
                delete design[group].name;
                for (let key in design[group]) {
                    const keyName = camelize(group, key);
                    const value = design[group][key];
                    nextDesign[keyName] = value;
                }
            }
        };
        // Return state
        if (number >= 0) setDesign(getDesign(number))
        else for (let i = 0; i < 3; i++) setDesign(getDesign(i));
        this.props.action.setAppState({ design: nextDesign });
    };

    // Remove renders depending on active menu
    clearRender = (...typeArray) => {
        const nextRender = {};
        typeArray.forEach(type => nextRender[type] = null);
        this.props.action.setAppState({ render: nextRender });
    };

    // Render
    render() { return null; }
};