// I M P O R T

// Element
import Letter from './Letter';

// Library
import { warpArray } from '../library/design/warp';
import { splitObject } from '../library/function/object';

// E X P O R T

export default function Word(props) {

    // Asset
    const { design, gross, index } = props;
    const { noDecal, noScale, noShift, noSlant } = props;
    const { warp } = splitObject(design);
    const factor = props.factor;
    const letterArray = props.input.split('');

    const color = props.color || warp.color(design.colorLetter, gross, index);
    const decal = noDecal ? warpArray[0].decal() : warp.decal(factor, gross, index);
    const scale = noScale ? warpArray[0].scale() : warp.scale(factor, gross, index);
    const shift = noShift ? warpArray[0].shift() : warp.shift(factor, gross, index);
    const slant = noSlant ? warpArray[0].slant() : warp.slant(factor, gross, index);

    // Component
    const Decal = () => <div style={{
        background: color,
        width: decal.w + '%',
        height: decal.h + '%',

        position: 'absolute',
        top: decal.y + '%',
        left: decal.x + '%',
        transform: `
            rotate(${decal.r}turn)
            scale(${decal.s[0]}, ${decal.s[1]})
        `
    }} />;
    const Input = () => <div style={{
        color: color,
        fontFamily: design.fontFamily,
        fontStyle: design.fontStyle,
        fontWeight: design.fontWeight,
        display: 'flex',
        userSelect: 'none'
    }}>
        {letterArray.map((letter, index) => (
            <Letter
                // Attribute
                noDecal
                // Content
                key={index}
                // Property
                color={color}
                design={design}
                gross={letterArray.length}
                index={index}
                input={letter}
            />
        ))}
    </div>;

    // Render
    return <div className="word">
        <div style={{
            position: 'relative',
            transform: `
                rotate(${slant}turn)
                scale(${scale[0]}, ${scale[1]})
                translate(${shift[0] + 'px'}, ${shift[1] + 'px'})
            `
        }}>
            <Decal />
            <Input />
        </div>
    </div>
}