// I M P O R T

// Module
import styled from 'styled-components';

// Style
import '../style/footer.css';

// S T Y L E D

const Styled = styled.footer(props => {
    const { design } = props;
    return `
        color: ${design.colorLetter[0]};
    `;
})

// E X P O R T

export default function Footer(props) {

    // Asset
    const { design } = props;

    // Render
    return <Styled design={design}>
        <span>V01.07</span>
        <span>MADE BY BIGBIGBIT</span>
    </Styled>;
};