// E X P O R T

export const blendHex = (hex1, hex2, bias = 0.5) => {
    const hexToRgb = hex => hex
        .match(hex.length > 4 ? /\w\w/g : /\w/g)
        .map(digit => parseInt(digit, 16));
    const blendRgb = (rgb1, rgb2) => (
        Math.round(rgb1 + (rgb2 - rgb1) * bias)
            .toString(16).padStart(2, '0')
    );
    const
        rgb1 = hexToRgb(hex1),
        rgb2 = hexToRgb(hex2);
    const
        red = blendRgb(rgb1[0], rgb2[0]),
        green = blendRgb(rgb1[1], rgb2[1]),
        blue = blendRgb(rgb1[2], rgb2[2]);
    return `#${red}${green}${blue}`;
};

export const hexToGradient = (array, type = 'linear', direction = 135) => {
    if (array.length < 2) return array[0]
    else return (
        `${type}-gradient(${direction}deg, ${array.map((hex, index) => {
            const length = array.length - 1;
            const percentage = index / length * 80 + 10 + '%';
            return `${hex} ${percentage}`;
        })})`
    );
};

export const hexToRgba = (hex, alpha = 1) => {
    const rgb = hex
        .match(hex.length > 4 ? /\w\w/g : /\w/g)
        .map(digit => parseInt(digit, 16));
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
};