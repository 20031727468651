// I M P O R T

// Library
import { rule } from '../../library/rule';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { design } = props;
    return `
        & button {
            color: ${design.colorActive[1]};
            font-family: 'Nunito', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: ${32 * rule.line.height + 'px'};

            background: transparent;
            border: none;
            border-radius: 5px;
            box-shadow: 0 0 0 3px ${design.colorActive[1]};

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        & button.active {
            color: ${design.colorActive[0]};

            box-shadow: 0 0 0 3px ${design.colorActive[0]};
            cursor: hand;
            cursor: pointer;
        }
    `;
});

// E X P O R T

export default function Export(props) {

    // Asset
    const { design, render, style } = props;

    // Function
    const download = () => {
        let link = document.createElement('a');
        link.download = 'test.png';
        link.href = render.export;
        link.click();
    };

    // Render
    return <Styled design={design} style={style}>
        <button
            className={render.export ? 'active' : ''}
            onClick={() => render.export ? download() : null}
        >
            Download
        </button>
    </Styled>;
};