// I M P O R T

// Asset
import placeholder from '../../asset/bitmap/placeholder.png';

// Element
import Image from '../../element/Image';

// E X P O R T

export default function Export(props) {

    // Asset
    const { render, style } = props;

    // Render
    return <div style={style}>
        <Image input={render.design || placeholder} />
    </div>;
};