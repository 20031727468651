// I M P O R T

// Library
import { randomEntry } from '../function/random';

// E X P O R T

export const fontArray = [{
    name: 'arial',
    family: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    style: 'normal',
    weight: 700
}, {
    name: 'arial-rounded',
    family: '"Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif',
    style: 'normal',
    weight: 400
}, {
    name: 'consolas',
    family: 'Consolas, monaco, monospace',
    style: 'normal',
    weight: 400
}, {
    name: 'courier',
    family: '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    style: 'normal',
    weight: 700
}, {
    name: 'futura',
    family: 'Futura, "Trebuchet MS", sans-serif',
    style: 'normal',
    weight: 400
}, {
    name: 'georgia',
    family: 'Georgia, "Times New Roman", Times, serif',
    style: 'italic',
    weight: 400
}, {
    name: 'gillsans',
    family: '"Gill Sans", "Gill Sans MT", Calibri, sans-serif',
    style: 'italic',
    weight: 700
}, {
    name: 'times',
    family: '"Times New Roman", Times, serif',
    style: 'normal',
    weight: 400
}, {
    name: 'trebuchet',
    family: '"Trebuchet MS", "Lucida Grande", sans-serif',
    style: 'italic',
    weight: 400
}];

export const font = randomEntry(fontArray);