// I M P O R T

// Asset
import { IconCreate, IconDesign, IconExport } from '../../asset/vector/icon';
import { ShapeBalloon, ShapeBalloonStroke } from '../../asset/vector/shape';
import { ShapeBubble, ShapeBubbleStroke } from '../../asset/vector/shape';

// Library
import { hexToRgba } from '../../library/function/color';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client, design } = props;
    const style = {};
    style.general = `
        & ul li svg.icon g {
            fill: ${design.colorActive[1]};
        }
        & ul li.active svg.icon g {
            fill: ${design.colorActive[0]};
        }
    `;
    style.desktop = `
        & ul li:hover svg {
            top: 2px;
        }
        & ul li svg.shape {
            filter: drop-shadow(0 2px 1px ${hexToRgba(design.colorLayout[2], 0.3)});
        }
        & ul li svg.shape g {
            fill: ${design.colorLayout[0]};
        }
        & ul li:hover svg.shape {
            filter: drop-shadow(0 0 0 ${hexToRgba(design.colorLayout[2], 0)});
        }
    `;
    style.mobile = `
        background: ${design.colorLayout[0]};
        border-radius: 10px 10px 0 0;
        box-shadow: 0 1px ${hexToRgba(design.colorLayout[2], 0.1)};

        & ul li svg.shape g {
            fill: ${design.colorActive[1]};
        }
        & ul li.active svg.shape g {
            fill: ${design.colorActive[0]};
        }
    `;
    return style.general + style[client.platform];
});

// E X P O R T

export default function Menu(props) {

    // Asset
    const { action, active, client, design } = props;

    // Component
    const Button = (props) => {
        const { index, name } = props;
        const Icon = () => ({
            "create": <IconCreate className="icon" />,
            "design": <IconDesign className="icon" />,
            "export": <IconExport className="icon" />
        }[name]);
        const Shape = () => (
            name === 'create' ?
                client.platform === 'desktop' ?
                    <ShapeBalloon className="shape" /> :
                    <ShapeBalloonStroke className="shape" /> :
                client.platform === 'desktop' ?
                    <ShapeBubble className="shape" /> :
                    <ShapeBubbleStroke className="shape" />
        );
        return <li
            className={active.editorMenu === index ? 'active' : ''}
            onClick={() => action.set({ editorMenu: index })}
        >
            <Icon />
            <Shape />
        </li>;
    };

    // Render
    return <Styled id="editor-menu" client={client} design={design}>
        <ul>
            <Button index={0} name="create" />
            <Button index={1} name="design" />
            <Button index={2} name="export" />
        </ul>
    </Styled>;
};