// I M P O R T

// Component
import Create from './ToolCreate';
import Design from './ToolDesign';
import Export from './ToolExport';

// Library
import { hexToRgba } from '../../library/function/color';

// Module
import styled from 'styled-components';

// S T Y L E D

const Styled = styled.div(props => {
    const { client, design } = props;
    const style = {};
    style.general = `
        background: ${design.colorLayout[0]};
        box-shadow: 0 2px 2px ${hexToRgba(design.colorLayout[2], 0.3)};
    `;
    style.desktop = `
        border-radius: 15px;
    `;
    style.mobile = `
        border-radius: 0 0 10px 10px;
    `;
    return style.general + style[client.platform];
});

// E X P O R T

export default function Tool(props) {

    // Asset
    const { client, design } = props;
    const active = props.active.editorMenu;
    const style = { display: 'none', pointerEvents: 'none' };

    // Render
    return <Styled id="editor-tool" client={client} design={design}>
        <Create {...props} style={active === 0 ? null : style} />
        <Design {...props} style={active === 1 ? null : style} />
        <Export {...props} style={active === 2 ? null : style} />
    </Styled>;
};