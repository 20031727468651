// E X P O R T

export const slant = {
    default: () => {
        return 0;
    },
    random: (factor = 0.25) => {
        return Math.random() * 0.4 * factor - 0.2 * factor;
    },
    updown: (factor, gross, index) => {
        return index % 2 * 0.5;
    },
    zigzag: (factor = 0.25, gross, index) => {
        return (index % 2 * 0.2 - 0.1) * factor;
    }
};